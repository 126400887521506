import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import { createRoot } from 'react-dom/client';

import App from './App';
import NotFound from './NotFound';

import './i18n' 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <BrowserRouter>
      <React.Suspense fallback="loading">
    <Routes>
      <Route exact path='/' element={<App />}/>
      <Route exact path='/:eventTypeId' element={<App />}/>
      <Route component={<NotFound />} />
        {/* <App /> */}
    </Routes>
      </React.Suspense>
  </BrowserRouter>
  
);
