import React from 'react'
import styles from "./Footer.module.css"
import LangSwitcher from './LangSwitcher';

import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Footer() {
    const { t } = useTranslation();
    const year = new Date().getFullYear().toString();

  return (
    <footer className={styles.footer}>
        <LangSwitcher />
        <div className={styles.copyright}>
          &copy;{year} {t("copyrights")}
        </div>
    </footer>
  )
}

export default Footer