import React from 'react'
import styles from './Footer.module.css';
import { useTranslation } from "react-i18next";

const lngs = {
    en: {nativeName:'EN', flag: '🇺🇸'},
    it: {nativeName:'IT', flag: '🇮🇹'}
}



function LangSwitcher() {
    const {t, i18n} = useTranslation();
  return (
    <ul className={styles.LangSwitcherList}>
    { Object.keys(lngs).map((lng) => (
        <li key={lng} onClick={() => {i18n.changeLanguage(lng)}} disabled={i18n.resolvedLanguage === lng} className={styles.LangSwitcherItem}>{lngs[lng].nativeName} {lngs[lng].flag}</li>
    )) }
    </ul>
  )
}

export default LangSwitcher