import React, { useState, useEffect } from 'react'
import useCountdown  from '../hooks/useCountdown';
import ShowCounter from './ShowCounter';

  function CountDown ({eventTitle, eventLabel, targetDate, onTimeIsUp, serverNow}) {
    const [days, hours, minutes, seconds] = useCountdown(targetDate, serverNow);

    useEffect(() => {
      if (days + hours + minutes + seconds <= 0) {
        onTimeIsUp();
      }
    }, [days, hours, minutes, seconds, onTimeIsUp]);
    {
      return (
        <>
        <p>{eventLabel}</p>
        <ShowCounter
          eventTitle={eventTitle}
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
        </>
      );
    }
  };
  
export default CountDown