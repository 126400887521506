import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


const languages = [
    {
      code: "it",
      name: "Italiano",
      country_code: "it",
    },
    {
      code: "en",
      name: "English",
      country_code: "us",
    },
  ];
  
  i18n
    .use(initReactI18next) 
    .use(LanguageDetector)  
    .use(HttpApi) 
    .init({
      supportedLngs: ['en', 'it'],
      fallbackLng: "en",
      detection: {
        // order: ['path', 'cookie', 'htmlTag', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'subdomain'],
        order: ['querystring'],
        // caches: ['cookie'],
      },
      backend: {
        loadPath: 'assets/locales/{{lng}}/translation.json',
      }
    });