import React from 'react'
import Button from "./Button";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function NoEvent({onClick}) {
    const { t } = useTranslation();
  return (
    <>
    <p>{t("noEventLabel")}</p>
    
    </>
  )
}

export default NoEvent