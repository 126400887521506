import React from 'react'
import DateTimeDisplay from './DateTimeDisplay'
import styles from './ShowCounter.module.css'

import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ShowCounter({ days, hours, minutes, seconds }) {
  const { t } = useTranslation();
  return (
      <>
    
      
    <div className={styles.showCounter}>
      <DateTimeDisplay value={days} type={t('timerDay')} isDanger={false} isZero={days === 0}/>
        <p>:</p>
        <DateTimeDisplay value={hours} type={t('timerHours')} isDanger={false} isZero={days === 0 && hours === 0}/>
        <p>:</p>
        <DateTimeDisplay value={minutes} type={t('timerMinutes')} isDanger={days === 0 && hours === 0 && minutes <= 2} isZero={days === 0 && hours === 0 && minutes === 0}/>
        <p>:</p>
        <DateTimeDisplay value={seconds} type={t('timerSeconds')} isDanger={days === 0 && hours === 0 && minutes <= 2 } isZero={false}/>
     
    </div>
    </>
  )
}

export default ShowCounter