import { useTranslation } from 'react-i18next'
import Button from './Button';
import styles from './ExpiredNotice.module.css';

function EventError({message, onRecall}) {
  const { t } = useTranslation();

  return (
    <div className={styles.expiredNotice}>
        <p>{t("eventErrorHandler")}</p>
        {console.log(message)}
        <div className={styles.cardActions}>
          <Button onClick={onRecall} >
            {t('cta_button_error')}
          </Button>
      </div>
    </div>
  )
}

export default EventError