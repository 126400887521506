import React from "react";
import styles from "./Skeleton.module.css";

function Skeleton() {
  return (
    <div className={styles.skeletonApp}>
      <div className={styles.skeletonOverlay}>
        <header className={styles.skeletonHeader}>
          <div className={styles.skeletonLogo}></div>
        </header>
        <div className={styles.skeletonCard}>
          <div className={styles.skeletonCardHeader}>
              <div className={styles.skeletonCardHeaderTitle}></div>
          </div>
          <div className={styles.skeletonCardBody}>
            <div className={styles.skeletonCardHeaderParagraph}></div>
            <div className={styles.skeletonCountDownRow}>
              <div className={styles.skeletonCountDownCol}></div>
              <div className={styles.skeletonCountDownCol}></div>
              <div className={styles.skeletonCountDownCol}></div>
              <div className={styles.skeletonCountDownCol}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
