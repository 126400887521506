import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import "./App.css";
import CountDown from "./components/CountDown";
import Skeleton from "./components/Skeleton/Skeleton";
import Header from "./components/Header";
import Footer from "./components/Footer";

import NoEvent from "./components/NoEvent";
import EventDate from "./components/EventDate";
import EventError from "./components/EventError";
import ExpiredNotice from "./components/ExpiredNotice";

function App() {
  let envVar = process.env.REACT_APP_ENV;
  let serverUrl = process.env.REACT_APP_SERVER_PROD_API;
  
  let apiKey = process.env.REACT_APP_SERVER_PROD_API_KEY;
  envVar === "stage" && (serverUrl = process.env.REACT_APP_SERVER_STAGE_API);
  envVar === "stage" && (apiKey = process.env.REACT_APP_SERVER_STAGE_API_KEY);

  const search = useLocation().search;
  let sid = new URLSearchParams(search).get("sid");
  let lang = new URLSearchParams(search).get("lng");

  let { eventTypeId } = useParams();
  eventTypeId === undefined && (eventTypeId = `ay`);

  const currentLanguageCode = (document.documentElement.lang = i18next.language);
  const { t } = useTranslation();

  

  useEffect(() => {
    document.title = t("appTitle");
  }, [currentLanguageCode, t, serverUrl]);

  const [data, setData] = useState();
  const [eventName, setEventName] = useState();
  const [targetDateV, setTargetDateV] = useState();
  const [nowServer, setNowServer] = useState();
  const [loading, setLoading] = useState(true);
  const [clickDayUrl, setClickDayUrl] = useState();
  const [error, setError] = useState();

  const [eventStatus, setEventStatus] = useState();
  const [isCountdownExpired, setIsCountdownExpired] = useState(false);


  async function callAPI() {
    const myHeaders = new Headers({
      "x-api-key": apiKey,
    });
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(`${serverUrl}?eventTypeId=${eventTypeId}`, requestOptions);
  
      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log(data);
      if (Object.keys(data).length === 0) {
        throw new Error("Empty response body");
      }
  
      const {
        eventName,
        eventTimestamp,
        currentTimestamp,
        url,
        status
      } = data;
  
      const eventDateObj = new Date(eventTimestamp);
      const eventDateTime = eventDateObj.toLocaleString("it-IT");
  
      setEventName(eventName);
      setNowServer(currentTimestamp);
      setTargetDateV(eventDateObj);
      setData(eventDateTime);
      setClickDayUrl(url);
      setEventStatus(status);
      setError(null);
      setLoading(false);
      
      return { url };
      
    } catch (error) {
      console.error("Failed to fetch event data:", error);
      setError(error);
      setLoading(false);
      retryHandler();
    }
  }

  // callAPI
  useEffect(() => {
    callAPI();
  }, []);
  
  function retryHandler () {
    setError(null)
    setLoading(true)
    callAPI()
  }

  let nameEventServer = eventName;
  let isEventOpen = ``;
  if (eventStatus === "NO_EVT") {isEventOpen = false;}
  else {
    isEventOpen = true
  }
  

  function onTimeIsUpHandler(){
    // callAPI();
    setIsCountdownExpired(true)
  }

  // gestione click
  async function eventClickHandler() {
    if (clickDayUrl) {
      const urlLang = lang === "it" ? "ita" : "eng";
      const completeUrl = `${clickDayUrl}&t_sid=${sid}&t_lingua=${urlLang}`;
      window.location.replace(completeUrl);
    } else {
      const response = await callAPI();
      const { url } = response;
  
      if (url) {
        const urlLang = lang === "it" ? "ita" : "eng";
        const completeUrl = `${url}&t_sid=${sid}&t_lingua=${urlLang}`;
        window.location.replace(completeUrl);
      } else {
        setIsCountdownExpired(true);
      }
    }
  }




  // gestione lingue e testi
  let theTitle = ``;
  let theLabel = t("eventLabel");
  let eventDatePrint = ``;
  if(eventTypeId === undefined){theTitle = t("eventTitle", {nameEventServer})};
  
  if(eventTypeId === "ay")  {
    theTitle = t("eventTitleAY"); theLabel = t("eventLabelAY")
  } ;
  if(eventTypeId === "ay-test")  {
    theTitle = t("eventTitleAY"); theLabel = t("eventLabelAY")
  } ;
  if(eventTypeId === "in") { 
    theTitle = t("eventTitleIN", {nameEventServer, interpolation: { escapeValue: false } }); theLabel = t("eventLabelIN", {nameEventServer, interpolation: { escapeValue: false } }) 
    eventDatePrint = <EventDate data={data}/>
  };
  if(eventTypeId === "in-test") { 
    theTitle = t("eventTitleIN", {nameEventServer, interpolation: { escapeValue: false } }); theLabel = t("eventLabelIN", {nameEventServer, interpolation: { escapeValue: false } }) 
    eventDatePrint = <EventDate data={data}/>
  };
  if(eventTypeId === "ffh") { 
    theTitle = t("eventTitleFFH"); theLabel = t("eventLabelFFH") 
    eventDatePrint = ``
  };
  if(eventTypeId === "ffh-test") { 
    theTitle = t("eventTitleFFH"); theLabel = t("eventLabelFFH") 
    eventDatePrint = <EventDate data={data}/>
  };
  !isEventOpen && ( theTitle = t("noEventTitle") );
  
  if (loading) return <Skeleton />;




  
  return (
    <div className="App">
      <div className="overlay">
        <Header />
        <div className="card">
          <div className="card-header">
            <h3>{theTitle}</h3>
          </div>
          <div className="card-body">
            
            {isEventOpen ? (
              !error ? 
              <>
              {eventDatePrint}
              {isCountdownExpired 
                ? <ExpiredNotice onClick={eventClickHandler} /> 
                : <CountDown
                  eventTitle={theTitle}
                  eventLabel={theLabel}
                  targetDate={targetDateV}
                  serverNow={nowServer}
                  onTimeIsUp={onTimeIsUpHandler}                  
                />}
              </>
              : <EventError message={error.message} onRecall={retryHandler}/>
            ) : 
              <NoEvent />
            }

          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
