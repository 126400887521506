import React from 'react'
import { useTranslation } from "react-i18next";
import i18next from "i18next";




function EventDate({data}) {
    const { t } = useTranslation();
    
  return (
    <>
    <p>{data} {t("eventTimeZone")}</p>
    </>
  )
}

export default EventDate