import React from 'react'
import { useTranslation } from "react-i18next";
import i18next from "i18next";


import Skeleton from "./components/Skeleton/Skeleton";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NoEvent from "./components/NoEvent";

function NotFound() {
const currentLanguageCode = (document.documentElement.lang =
        i18next.language);
    
const { t } = useTranslation();
let theTitle = t("noEventTitle");
function onGoBackHandler(){
    window.location.href = "https://www.unibocconi.it"
  }

  return (
    <div className="App">
      <div className="overlay">
        <Header />
        <div className="card">
          <div className="card-header">
            <h3>{theTitle}</h3>
          </div>
          <div className="card-body">
            <NoEvent onClick={onGoBackHandler}/>        
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default NotFound