import React from 'react'
import styles from './DateTimeDisplay.module.css';
import classNames from 'classnames/bind';

function DateTimeDisplay({value, type, isDanger, isZero}) {
  
  let cx = classNames.bind(styles);
  let className=cx({
    'countdown': true,
    'countdownDanger': isDanger,
    'countdownZero': isZero
  })

// const dangerField = isDanger ? `${styles.countdownDanger}` : `${styles.countdown}`;
// const zeroLand = isZero ? `${styles.countdownZero}` : `${styles.countdown}`;
  return (
    <div className={className}>
      <span className={styles.num}>{value}</span>
      <span className={styles.timeLabel}>{type}</span>
    </div>
  )
}

export default DateTimeDisplay  