import React from 'react'
import styles from './Button.module.css'

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function Button({onClick, children}) {
  const { t } = useTranslation();
  return (   
    <button onClick={onClick} className={styles.btn}>{children}</button>
  )
}

export default Button