import { useState, useEffect } from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'

import styles from './ExpiredNotice.module.css'

function ExpiredNotice({onClick}) {
  
  const { t } = useTranslation();
 

  return (
    <div className={styles.expiredNotice}>
      <span>{t('expireNotice')}</span>
      <div className={styles.cardActions}>
          <Button onClick={onClick}>{
            
            t('cta_button')

          }</Button>
      </div>
    </div>
  )
}

export default ExpiredNotice